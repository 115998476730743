

:root{
    --fw-reg: 400;
    --fw-semi: 700;
    --fw-bold: 900;

    --col-black: rgb(0, 0, 0);
    --col-white: rgb(241, 250, 238);
    --col-beige: rgb(230, 57, 70);
    --col-red: rgb(193, 18, 31);
    --col-grey: rgb(136, 134, 134);

    --transition: all .3s ease;
    --animation: spin 1s linear infinite;
  }
  html{
    font-family: "poppins", sans-serif;
    background-color: var(--col-black);
    color: var(--col-white);
  }
  
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }


  a{
    text-decoration: none;
    color: var(--col-red);
    font-weight: var(--fw-semi);
    &:hover{
      color: var(--col-beige);
      transition: var(--transition);
    }
  }
  li{
    text-decoration: none;
    list-style-type: none;
  }
  .btn{
    border: none;
    background: var(--col-red);
    color: var(--col-black);
    padding: 1rem;
    width: 100%;
    font-weight: var(--fw-semi);
    font-size: 1rem;
    &:hover{
      background: var(--col-beige);
      transition: var(--transition);
    }
  }
  @keyframes spin {
	  from {
		  transform: rotate(0deg);
	  }
	  to {
		  transform: rotate(360deg);
	  }
  }

  .rotor {
	  animation: var(--animation);
	  width: 6rem;
	  height: 6rem;
    object-fit: cover;
    margin: 1rem;
  }
  
  /* login component  */
 
  .auth-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    height: 100dvh;
    // width: 100%;
    // border: 1px solid yellow;
    img{
      width: 15rem;
      height: 15rem;
      object-fit: contain;
    }
    h2{
      font-size: 2rem;
      font-weight: var(--fw-bold);
      color: var(--col-red);
      padding-block: .5rem;
      text-align: center;
    }
    .error-message{
      text-align: end;
      color: var(--col-beige);
      font-weight: var(--fw-semi);
      font-size: .9rem;
    }
    form{
      // width: 70dvw;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      padding: 1rem;
      gap: 1rem;
      // border: 1px solid white;
      @media (max-width:800px) {
        width: 100%;
        
      }
      .input-group{
        width: 100%;
        label{
          font-weight: var(--fw-bold);
          font-size: 1rem;
        }
        input{
          width: 100%;
          font-size: 1rem;
          font-weight: var(--fw-semi);
          &:active{
            border: 1px solid --col-beige;
          }
          padding: 1rem;
          background: var(--col-grey);
          // border: 1px solid orangered;
          border-radius: 7px;
          color: var(--col-white);
        }
      }
      #forgot{
        text-align:start;
        font-weight: var(--fw-semi);
        font-size: .9rem;
        color: var(--col-white);
      }
      #google{
        border-radius: 10px;
        background: none;
      }
    }
    p{
      text-align: center;
      font-size: 1rem;
      padding-block-end: .8rem;
    }
  }

  // sign up comp 

 .signup-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100dvh;
  width: 100%;
  padding: 1rem;
  h2{
    font-size: 2.5rem;
    font-weight: var(--fw-bold);
    text-align: center;
  }
  #login{
    opacity: .7;
    font-weight: var(--fw-semi);
    padding: .5rem;
    
  }
  form{
    padding: 2rem;
    @media (max-width: 800px){
      width: 100%;

    }
    input{
      width: 100%;
      padding: .9rem 1rem;
      font-size: 1rem;
      font-weight: var(--fw-semi);
      background: var(--col-grey);
      margin-block-end: 1.2rem;
      color: var(--col-white);
      border: none;
      border-radius: 5px;
    }

  }
 } 


//  Hero Component 

.hero-cont{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  gap: 1rem;
  align-items: center;
  color: var(--col-red);
  justify-content: center;

  h2{
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: var(--fw-bold);
    text-align: center;
  }
  p{
    font-weight: var(--fw-bold);
    font-size: .8rem;
    margin-block-start: 2rem;
  }
  span{
    font-size: 1rem;
    font-weight: var(--fw-semi);
    text-align: center;
  }
  
}


// Disclaimer 

.disclaimer{
  height: 100dvh;
  width: 100%;
  .top-cont{
    height: 30dvh;
    width: 100%;
    background: var(--col-red);
    color: var(--col-white);
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      font-size: 1.5rem;
      font-weight: var(--fw-semi);
    }
  }
  .bottom-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 1.2rem;

    ul{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      li{
        font-weight: var(--fw-semi);
        font-size: 1rem;
        color: var(--col-red);
      }
    }
    p{
      font-weight: var(--fw-bold);
      font-size: 1rem;
      color: var(--col-red);
      margin-block-start: 2rem;
    }
  }
}




// Main page 


.main{
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 2rem;
  nav{
    background: var(--col-red);
    height: 15dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .8rem;
    width: 100%;
    .navv{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      h1{
        font-size: 1rem;
        font-weight: var(--fw-semi);
      }
      .logout{
        border: none;
        padding: .7rem 1rem;
        background: var(--col-black);
        color: var(--col-white);
        cursor: pointer;
        border-radius: 7px;
        font-weight: var(--fw-semi);
        &:hover{
          background: var(--col-beige);
          transition: var(--transition);
        }
      }
    }
    p{
      text-align: center;
      font-size: 1rem;
      font-weight: var(--fw-semi);
      padding-block-end: 1rem;
    }
  }
  .main-container{
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    p{
      text-align: center;
      font-size: .9rem;
      font-weight: var(--fw-bold);
    }
    ul{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      li{
        display: flex;
        a{
          display: block;
          width: 100%;
          transition: transform .3s;
          &:hover{
            transform: scale(1.05);
          }
          img{
            width: 100%;
            height: 4rem;
            display: block;
            border-radius: 7px;
          }
        }
      }
      
    }

  }
  .link{
    background: var(--col-red);
    width: 100%;
    padding: .7rem 0 0 0;
    margin: 0;
  }
}


.get-prediction-container{
  width: 100%;
  height: 100dvh;
  padding: 1rem;
  position: relative;
  // margin: 2rem;
  .signal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    h2{
      font-size: 2rem;
      font-weight: var(--fw-bold);

    }
    p{
      font-size: 1rem;
      font-weight: var(--fw-semi);
    }
    background-color:bisque ;
    .loader{
      display: flex;
      align-items: center;
      // gap: 1rem;
    }

  }
  .overlay{
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 800;
  }
  .blur{
    filter: blur(5px);
    pointer-events: none;
  }
  .popup{
    position: fixed;
    width: 100%;
    top: 40%;
    .wait-message{
      color: var(--col-beige);
      margin-block-start: 1rem;
    }
    @media (min-width: 800px) {
      left: 30%;
      width: 30rem;
    }
    transform: translate (-50%, -50%);
    background-color: rgba(255, 255, 255, .1);
    padding: 2rem;
    border-radius: 10px;
    z-index: 1000;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1), 0 16px 32px rgba(0, 0, 0, .1);
    backdrop-filter: blur(10px);
    h2{
      font-size: 1.4rem;
      font-weight: var(--fw-semi);
      text-align: start;
      color: var(--col-white);
      margin-block-end: 1rem;
    }
    p{
      padding-block: 1rem;
      font-size: .9rem;
      font-weight: var(--fw-semi);
      color: var(--col-white);
    }
    input{
      padding: .9rem;
      width: 80%;
      border: 1px solid --col-red;
    }
    button{
      padding: .7rem;
      border: none;
      background: none;
      font-weight: var(--fw-bold);
      color: var(--col-white);
      font-size: 1rem;
      cursor: pointer;
      align-self: flex-end;
      &:hover{
        color: var(--col-beige);
        transition: var(--transition);
      }
    }
  }
  
  .predictor{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
    // border: 1px solid white;
    padding: 1rem;
    .blur{
      pointer-events: none;
    }
    img{
      width: 15rem;
      height: 15rem;
      object-fit: contain;
      padding: .5rem;
    }
    p{
      font-weight: var(--fw-semi);
      font-size: 1.5rem;
      color: var(--col-red);
      text-align: center;
    }
    h1{
      margin: 1rem;
      font-size: 4rem;
      font-weight: var(--fw-semi);
      color: var(--col-white);
      margin-block-end: 3rem;
    }
    
  }
}



// Error page

.error-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  height: 100dvh;
  width: 100%;
  .error{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    li{
      text-decoration: none;
      list-style: none;
      font-size: 2rem;
      color: var(--col-beige);
      // background-color: var(--col-red);
    }
  }
  h2{
    font-size: 1.6rem;
    font-weight: var(--fw-semi);
    text-align: center;
  }
  p{
    text-align: center;
    font-size: 1rem;
    font-weight: var(--fw-semi);
    opacity: .7;
    a{
      color: var(--col-red);
    }
  }
  button{
    border: none;
    background: none;
    font-size: 1rem;
    font-weight: var(--fw-bold);
    color: var(--col-red);
    &:hover{
      color: var(--col-beige);
      transition: var(--transition);
    }
  }
  
}

.message {
	color: green;
	margin-block-start: 1rem;
}


// disclaimer 
.cont-debug{
  width: 100%;
  height: 100dvh;
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;

}

.disc-cont{
  // border: 1px solid red;
  background-color: rgba(255, 255, 255, .1);
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1), 0 16px 32px rgba(0, 0, 0, .1);
  backdrop-filter: blur(10px);
  color: var(--col-white);
  display: flex;
  flex-direction: column;
  height: 50%;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
  gap: .8rem;
  width: 50%;
  
  h3{
    font-size: 1.5rem;
    font-weight: var(--fw-semi);
    text-align: center;
  }
  p{
    font-size: 1rem;
    font-weight: var(--fw-semi);
    opacity: .7;
    text-align: start; 
  }
  ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:1rem;
    padding-inline-start: 1rem;
    li{
      font-size: 1rem;
      font-weight: var(--fw-semi);
      a{
        padding-inline-start: .5rem;
        color: var(--col-red);
        &:hover{
          color: var(--col-beige);
          transition: color .3s ease;
        }
      }
    }
  }
  .count{
    display: flex;
    align-items: center;
    margin: 1rem;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    .btnn{
      display: block;
      padding: .7rem 1rem;
      border-radius: 7px;
      border: none;
      background: none;
      font-size: 1rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .disc-cont{
    width: 100%;
    height: fit-content;
  }
  
}

.start-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100%;
}


// Language Switcher 
.language-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin: 20px;
  padding: .5rem; /* Adjust as needed */
}

.language-switcher span {
  margin-right: 10px;
  font-weight: bold;
}

.language-switcher select {
  padding: 5px;
  border-radius: 5px;
  // border: 1px solid #ccc;
  cursor: pointer;
}


// Admin

.addy{
  margin: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 80dvh;
  input{
    margin-block: 1rem;
    padding-block: .4rem;
  }
  #message{
    color: green;
  }
}




// //new
// /* styles.css */

// /* styles.css */
// .get-prediction-container {
//   text-align: center;
//   padding: 20px;
// }

// .predictor img {
//   width: 150px;
// }

// .predictor h1 {
//   font-size: 2em;
//   margin: 20px 0;
// }

// .predictor button {
//   padding: 10px 20px;
//   font-size: 1em;
//   background-color: #f44336;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }

// .predictor button:disabled {
//   background-color: #ccc;
// }

// .popup {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//   z-index: 1000;
// }

// .popup h2 {
//   margin-top: 0;
// }

// .popup .loader {
//   border: 4px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 4px solid #3498db;
//   width: 40px;
//   height: 40px;
//   animation: spin 2s linear infinite;
//   margin: 20px auto;
// }

// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }

// .error-page {
//   text-align: center;
//   padding: 20px;
// }

// .error-page h2 {
//   margin-top: 0;
// }

