:root {
  --fw-reg: 400;
  --fw-semi: 700;
  --fw-bold: 900;
  --col-black: rgb(0, 0, 0);
  --col-white: rgb(241, 250, 238);
  --col-beige: rgb(230, 57, 70);
  --col-red: rgb(193, 18, 31);
  --col-grey: rgb(136, 134, 134);
  --transition: all .3s ease;
  --animation: spin 1s linear infinite;
}

html {
  font-family: "poppins", sans-serif;
  background-color: var(--col-black);
  color: var(--col-white);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--col-red);
  font-weight: var(--fw-semi);
}
a:hover {
  color: var(--col-beige);
  transition: var(--transition);
}

li {
  text-decoration: none;
  list-style-type: none;
}

.btn {
  border: none;
  background: var(--col-red);
  color: var(--col-black);
  padding: 1rem;
  width: 100%;
  font-weight: var(--fw-semi);
  font-size: 1rem;
}
.btn:hover {
  background: var(--col-beige);
  transition: var(--transition);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotor {
  animation: var(--animation);
  width: 6rem;
  height: 6rem;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 1rem;
}

/* login component  */
.auth-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  height: 100dvh;
}
.auth-form-container img {
  width: 15rem;
  height: 15rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.auth-form-container h2 {
  font-size: 2rem;
  font-weight: var(--fw-bold);
  color: var(--col-red);
  padding-block: 0.5rem;
  text-align: center;
}
.auth-form-container .error-message {
  text-align: end;
  color: var(--col-beige);
  font-weight: var(--fw-semi);
  font-size: 0.9rem;
}
.auth-form-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}
@media (max-width: 800px) {
  .auth-form-container form {
    width: 100%;
  }
}
.auth-form-container form .input-group {
  width: 100%;
}
.auth-form-container form .input-group label {
  font-weight: var(--fw-bold);
  font-size: 1rem;
}
.auth-form-container form .input-group input {
  width: 100%;
  font-size: 1rem;
  font-weight: var(--fw-semi);
  padding: 1rem;
  background: var(--col-grey);
  border-radius: 7px;
  color: var(--col-white);
}
.auth-form-container form .input-group input:active {
  border: 1px solid --col-beige;
}
.auth-form-container form #forgot {
  text-align: start;
  font-weight: var(--fw-semi);
  font-size: 0.9rem;
  color: var(--col-white);
}
.auth-form-container form #google {
  border-radius: 10px;
  background: none;
}
.auth-form-container p {
  text-align: center;
  font-size: 1rem;
  padding-block-end: 0.8rem;
}

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100dvh;
  width: 100%;
  padding: 1rem;
}
.signup-container h2 {
  font-size: 2.5rem;
  font-weight: var(--fw-bold);
  text-align: center;
}
.signup-container #login {
  opacity: 0.7;
  font-weight: var(--fw-semi);
  padding: 0.5rem;
}
.signup-container form {
  padding: 2rem;
}
@media (max-width: 800px) {
  .signup-container form {
    width: 100%;
  }
}
.signup-container form input {
  width: 100%;
  padding: 0.9rem 1rem;
  font-size: 1rem;
  font-weight: var(--fw-semi);
  background: var(--col-grey);
  margin-block-end: 1.2rem;
  color: var(--col-white);
  border: none;
  border-radius: 5px;
}

.hero-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  gap: 1rem;
  align-items: center;
  color: var(--col-red);
  justify-content: center;
}
.hero-cont h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: var(--fw-bold);
  text-align: center;
}
.hero-cont p {
  font-weight: var(--fw-bold);
  font-size: 0.8rem;
  margin-block-start: 2rem;
}
.hero-cont span {
  font-size: 1rem;
  font-weight: var(--fw-semi);
  text-align: center;
}

.disclaimer {
  height: 100dvh;
  width: 100%;
}
.disclaimer .top-cont {
  height: 30dvh;
  width: 100%;
  background: var(--col-red);
  color: var(--col-white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.disclaimer .top-cont p {
  font-size: 1.5rem;
  font-weight: var(--fw-semi);
}
.disclaimer .bottom-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1.2rem;
}
.disclaimer .bottom-cont ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.disclaimer .bottom-cont ul li {
  font-weight: var(--fw-semi);
  font-size: 1rem;
  color: var(--col-red);
}
.disclaimer .bottom-cont p {
  font-weight: var(--fw-bold);
  font-size: 1rem;
  color: var(--col-red);
  margin-block-start: 2rem;
}

.main {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main nav {
  background: var(--col-red);
  height: 15dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
}
.main nav .navv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.main nav .navv h1 {
  font-size: 1rem;
  font-weight: var(--fw-semi);
}
.main nav .navv .logout {
  border: none;
  padding: 0.7rem 1rem;
  background: var(--col-black);
  color: var(--col-white);
  cursor: pointer;
  border-radius: 7px;
  font-weight: var(--fw-semi);
}
.main nav .navv .logout:hover {
  background: var(--col-beige);
  transition: var(--transition);
}
.main nav p {
  text-align: center;
  font-size: 1rem;
  font-weight: var(--fw-semi);
  padding-block-end: 1rem;
}
.main .main-container {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.main .main-container p {
  text-align: center;
  font-size: 0.9rem;
  font-weight: var(--fw-bold);
}
.main .main-container ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.main .main-container ul li {
  display: flex;
}
.main .main-container ul li a {
  display: block;
  width: 100%;
  transition: transform 0.3s;
}
.main .main-container ul li a:hover {
  transform: scale(1.05);
}
.main .main-container ul li a img {
  width: 100%;
  height: 4rem;
  display: block;
  border-radius: 7px;
}
.main .link {
  background: var(--col-red);
  width: 100%;
  padding: 0.7rem 0 0 0;
  margin: 0;
}

.get-prediction-container {
  width: 100%;
  height: 100dvh;
  padding: 1rem;
  position: relative;
}
.get-prediction-container .signal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background-color: bisque;
}
.get-prediction-container .signal h2 {
  font-size: 2rem;
  font-weight: var(--fw-bold);
}
.get-prediction-container .signal p {
  font-size: 1rem;
  font-weight: var(--fw-semi);
}
.get-prediction-container .signal .loader {
  display: flex;
  align-items: center;
}
.get-prediction-container .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 800;
}
.get-prediction-container .blur {
  filter: blur(5px);
  pointer-events: none;
}
.get-prediction-container .popup {
  position: fixed;
  width: 100%;
  top: 40%;
  transform: translate -50%, -50%;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 10px;
  z-index: 1000;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.get-prediction-container .popup .wait-message {
  color: var(--col-beige);
  margin-block-start: 1rem;
}
@media (min-width: 800px) {
  .get-prediction-container .popup {
    left: 30%;
    width: 30rem;
  }
}
.get-prediction-container .popup h2 {
  font-size: 1.4rem;
  font-weight: var(--fw-semi);
  text-align: start;
  color: var(--col-white);
  margin-block-end: 1rem;
}
.get-prediction-container .popup p {
  padding-block: 1rem;
  font-size: 0.9rem;
  font-weight: var(--fw-semi);
  color: var(--col-white);
}
.get-prediction-container .popup input {
  padding: 0.9rem;
  width: 80%;
  border: 1px solid --col-red;
}
.get-prediction-container .popup button {
  padding: 0.7rem;
  border: none;
  background: none;
  font-weight: var(--fw-bold);
  color: var(--col-white);
  font-size: 1rem;
  cursor: pointer;
  align-self: flex-end;
}
.get-prediction-container .popup button:hover {
  color: var(--col-beige);
  transition: var(--transition);
}
.get-prediction-container .predictor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 1rem;
}
.get-prediction-container .predictor .blur {
  pointer-events: none;
}
.get-prediction-container .predictor img {
  width: 15rem;
  height: 15rem;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 0.5rem;
}
.get-prediction-container .predictor p {
  font-weight: var(--fw-semi);
  font-size: 1.5rem;
  color: var(--col-red);
  text-align: center;
}
.get-prediction-container .predictor h1 {
  margin: 1rem;
  font-size: 4rem;
  font-weight: var(--fw-semi);
  color: var(--col-white);
  margin-block-end: 3rem;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  height: 100dvh;
  width: 100%;
}
.error-page .error {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.error-page .error li {
  text-decoration: none;
  list-style: none;
  font-size: 2rem;
  color: var(--col-beige);
}
.error-page h2 {
  font-size: 1.6rem;
  font-weight: var(--fw-semi);
  text-align: center;
}
.error-page p {
  text-align: center;
  font-size: 1rem;
  font-weight: var(--fw-semi);
  opacity: 0.7;
}
.error-page p a {
  color: var(--col-red);
}
.error-page button {
  border: none;
  background: none;
  font-size: 1rem;
  font-weight: var(--fw-bold);
  color: var(--col-red);
}
.error-page button:hover {
  color: var(--col-beige);
  transition: var(--transition);
}

.message {
  color: green;
  margin-block-start: 1rem;
}

.cont-debug {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disc-cont {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  color: var(--col-white);
  display: flex;
  flex-direction: column;
  height: 50%;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
  gap: 0.8rem;
  width: 50%;
}
.disc-cont h3 {
  font-size: 1.5rem;
  font-weight: var(--fw-semi);
  text-align: center;
}
.disc-cont p {
  font-size: 1rem;
  font-weight: var(--fw-semi);
  opacity: 0.7;
  text-align: start;
}
.disc-cont ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding-inline-start: 1rem;
}
.disc-cont ul li {
  font-size: 1rem;
  font-weight: var(--fw-semi);
}
.disc-cont ul li a {
  padding-inline-start: 0.5rem;
  color: var(--col-red);
}
.disc-cont ul li a:hover {
  color: var(--col-beige);
  transition: color 0.3s ease;
}
.disc-cont .count {
  display: flex;
  align-items: center;
  margin: 1rem;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}
.disc-cont .count .btnn {
  display: block;
  padding: 0.7rem 1rem;
  border-radius: 7px;
  border: none;
  background: none;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .disc-cont {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
  }
}
.start-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100%;
}

.language-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem; /* Adjust as needed */
}

.language-switcher span {
  margin-right: 10px;
  font-weight: bold;
}

.language-switcher select {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.addy {
  margin: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 80dvh;
}
.addy input {
  margin-block: 1rem;
  padding-block: 0.4rem;
}
.addy #message {
  color: green;
}/*# sourceMappingURL=app.css.map */